import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import H2 from '../../../components/Titles/h2';
import SystempayComponent from '../../../components/Paiement/systempay';
import Input from '../../../components/Inputs/input';
import Icon from '../../../components/Images/icon';
import iconPhone from "../../../assets/icons/contact/phone.svg";
import iconCalendar from "../../../assets/icons/quotation/calendar.svg";
import H4 from '../../../components/Titles/h4';

export default function DetailPaiementQuotation({ selectedCard, quotation, returnedData }) {
    const { t } = useTranslation();
    const [description, setDescription] = useState(null);

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    useEffect(() => {
        if (selectedCard === "payLater") {
            setDescription(t(`pageQuotation.step3.${selectedCard}.description1`) + quotation.prices.depositPercentage + "%" + t(`pageQuotation.step3.${selectedCard}.description2`));

        } else {
            setDescription(t(`pageQuotation.step3.${selectedCard}.description`));
        }

    }, [t, selectedCard, quotation.prices.depositPercentage])

    return (
        <div className='px-4 md:px-0'>
            <div className='flex flex-col gap-5 md:flex-row'>
                <div className='w-full md:w-5/12'>
                    <H2 title={t(`pageQuotation.step3.${selectedCard}.title`)} />
                    <p className='text-description opacity-60 mt-3'>
                        {description}
                    </p>

                    {selectedCard === "payByTransfer" && (
                        <p className='text-description opacity-60 mt-5'>
                            {t("pageQuotation.step3.payByTransfer.detailsName")}
                            <span className='uppercase'>{quotation.customer.lastname}</span>&nbsp;
                            <span>{quotation.customer.firstname}</span>
                            <br />
                            {t("pageQuotation.step3.payByTransfer.detailsNumberQuotation")}
                            {quotation.mainInfos.quotationNumber}
                        </p>
                    )}
                </div>

                <div className='w-full md:w-7/12 mb-8'>
                    {(selectedCard === "payNow" || selectedCard === "payLater") && (
                        <SystempayComponent data={{ paymentMethod: selectedCard, quotationID: quotation._id, language: quotation.mainInfos.language }} returnedData={handleReturnedData} />
                    )}

                    {selectedCard === "payByTransfer" && (
                        <div>
                            <div>
                                <Input label={t("bankCode.label")} value={t("bankCode.value")} />
                                <Input label={t("boxCode.label")} value={t("boxCode.value")} />
                                <Input label={t("accountNumber.label")} value={t("accountNumber.value")} />
                                <Input label={t("RIB.label")} value={t("RIB.value")} />
                                <Input label={t("IBAN.label")} value={t("IBAN.value")} />
                                <Input label={t("BIC.label")} value={t("BIC.value")} />
                            </div>

                            <div className='p-4'>
                                <H4 title={t('pageQuotation.step3.amountToPay') + quotation.prices.totalPrice + "€"} fontWeight='bold' />
                            </div>
                        </div>
                    )}

                    {selectedCard === "payByPhone" && (
                        <div>
                            <div className='flex flex-col gap-4 md:flex-row'>
                                <a href="tel:+33387880930" className="flex-1">
                                    <div className="p-4 xl:p-8 text-center w-fit m-auto flex flex-col items-center gap-4">
                                        <Icon icon={iconPhone} alt={t('contact.altIconPhone')} />
                                        <span className="font-bold text-md whitespace-nowrap">
                                            + 33 (0)3 87 88 09 30
                                        </span>
                                        <span className="text-sm whitespace-nowrap -mt-2">
                                            {t('pageQuotation.step3.payByPhone.language')}
                                        </span>
                                    </div>
                                </a>

                                <a href="tel:+33387880930" className="flex-1">
                                    <div className="p-4 xl:p-8 text-center w-fit m-auto flex flex-col items-center gap-4">
                                        <Icon icon={iconCalendar} alt={t('pageQuotation.step3.payByPhone.calendar.altIcon')} />
                                        <span className="font-bold text-md whitespace-nowrap">
                                            {t('pageQuotation.step3.payByPhone.calendar.days')}
                                        </span>
                                        <span className="text-sm whitespace-nowrap -mt-2">
                                            {t('pageQuotation.step3.payByPhone.calendar.hours')}
                                        </span>
                                    </div>
                                </a>
                            </div>
                            <div className='p-4'>
                                <H4 title={t('pageQuotation.step3.amountToTransfer') + quotation.prices.totalPrice + "€"} fontWeight='bold' />
                            </div>
                        </div>
                    )}

                    {selectedCard === "payLeftToPay" && (
                        <SystempayComponent data={{ paymentMethod: selectedCard, quotationID: quotation._id, language: quotation.mainInfos.language }} returnedData={handleReturnedData} />
                    )}
                </div>
            </div>
        </div>
    )
};