import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useTranslation } from 'react-i18next';

const SingleDateFieldInput = ({ label, name, value, minDate = new Date(1900, 0, 1), maxDate = new Date(), error, onChange, displayLabel = false, className = "" }) => {
    const { t, i18n } = useTranslation();

    const [selectedDate, setSelectedDate] = useState({
        startDate: new Date(value) || null,
        endDate: new Date(value) || null
    });

    const handleDateChange = (newValues) => {
        if (!newValues || !newValues.startDate) return;

        const updatedDate = {
            startDate: newValues.startDate,
            endDate: newValues.startDate
        };

        setSelectedDate(updatedDate);

        if (onChange) {
            onChange({ name, date: newValues.startDate });
        }
    };

    return (
        <div className="relative w-full">
            {displayLabel && (
                <label htmlFor={label} className="text-sm text-secondary block mb-2">
                    {t(`formInputs.${label}`)}
                </label>
            )}

            <Datepicker
                value={selectedDate}
                i18n={i18n.language}
                primaryColor="amber"
                startWeekOn="mon"
                minDate={minDate}
                maxDate={maxDate}
                useRange={false}
                asSingle={true}
                placeholder={t(`formInputs.${label}`)}
                displayFormat="DD/MM/YYYY"
                inputClassName={`bg-white w-full text-secondary text-sm border ${error ? "border-error" : "border-borderColor"} p-3 pl-10 rounded-lg ${className}`}
                toggleClassName={`absolute text-main left-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed ${className}`}
                onChange={handleDateChange}
            />
            {error && <p className="mt-1 text-xs text-error ml-1">{error}</p>}
        </div>
    );
};

export default SingleDateFieldInput;
