import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import H4 from '../../../components/Titles/h4';
import { faBath, faBed, faEuroSign, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullWidthImage from '../../../components/Images/image-full-width';
import ButtonFit from '../../../components/Buttons/button-fit';
import Helper from '../../../services/helper.service';

export default function AccommodationDisplayed({ accommodation, accommodationSelected, prices, totalPeople, onChange }) {
    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState(accommodationSelected);
    const { t } = useTranslation();

    useEffect(() => {
        setSelected(accommodationSelected);
    }, [accommodationSelected]);

    // Load images
    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(accommodation.map(async (name) => {
                try {
                    const image = await import(`./../../../assets/images/reservation/${name}.png`);
                    return image.default;
                } catch (error) {
                    console.error('Error loading image:', error);
                    return null;
                }
            }));

            setImages(loadedImages.filter(Boolean)); // More concise way to filter out null values
        };

        loadImages();
    }, [accommodation]);

    const handleSelectAccommodation = useCallback((item) => {
        const maxPeopleHome = 12;
        const maxPeopleAnnex = 10;

        if (item === "home") {
            if (totalPeople > maxPeopleHome) {
                return Helper.toast(t('errors.tooManyPeopleHome'), "error");
            }
        } else if (item === "annex") {
            if (totalPeople > maxPeopleAnnex) {
                return Helper.toast(t('errors.tooManyPeopleAnnex'), "error");
            }
        }

        setSelected(item);
        onChange(item);
    }, [onChange, totalPeople, t]);

    return (
        <>
            {accommodation.map((item, index) => (
                <div key={item} className={`relative w-full bg-white relative flex justify-between gap-4 p-4 ${selected.includes(item) ? "bg-main border-main border-2 shadow-xl" : "bg-white border-gray-50 border-2"}`}>
                    <div className='flex flex-col md:flex-row gap-2 md:gap-6 w-full'>
                        <div className='hidden md:flex'>
                            <FullWidthImage url={images[index]} alt={t(`reservation.accommodation.${item}.alt`)} customHeight={100} customWidth={100} divClassName='flex items-center' />
                        </div>
                        <div className='w-full left-0 bottom-0'>
                            <div className='flex justify-between'>
                                <div className='flex flex-col gap-1'>
                                    <H4 title={t(`reservation.accommodation.${item}.title`)} fontWeight='semibold' theme='dark' className='text-uppercase' />
                                    <div className='flex items-start 2xl:items-center opacity-50'>
                                        <FontAwesomeIcon icon={faUser} className='text-main' />
                                        <span className='text-sm ml-2'>{t(`reservation.accommodation.${item}.nbPersons`)}</span>
                                    </div>
                                    <div className='flex items-start 2xl:items-center opacity-50'>
                                        <FontAwesomeIcon icon={faBed} className='text-xs text-main' />
                                        <span className='text-sm ml-2'>{t(`reservation.accommodation.${item}.nbBeds`)}</span>
                                    </div>
                                    <div className='flex items-start 2xl:items-center opacity-50'>
                                        <FontAwesomeIcon icon={faBath} className='text-xs text-main' />
                                        <span className='text-sm ml-2'>{t(`reservation.accommodation.${item}.nbBathroom`)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col items-end gap-2 justify-between 2xl:items-end'>
                            <div className='flex flex-col items-end'>
                                {prices[item].price > 0 &&
                                    <div>
                                        <span className='text-main font-bold'>{prices[item].price}</span>
                                        <FontAwesomeIcon icon={faEuroSign} className='text-main' />
                                        <span>{t(`reservation.accommodation.${item}.price`)}</span>
                                    </div>
                                }
                                {prices[item].cleaning > 0 &&
                                    <div className="text-[10px] leading-[12px] tracking-[-0.2px] text-[#262525] opacity-50 whitespace-nowrap">
                                        <span>+{prices[item].cleaning}€</span>&nbsp;
                                        <span>{t(`reservation.accommodation.${item}.cleaning`)}</span>
                                    </div>
                                }
                            </div>
                            {selected !== item &&
                                <ButtonFit
                                    text={t(`inputs.add`)}
                                    onClick={() => handleSelectAccommodation(item)}
                                />}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
