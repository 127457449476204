import React, { useEffect, useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import H2 from '../../../components/Titles/h2';
import Link from '../../../components/Links/link';
import ImageBackground from '../../../components/Images/image-background';
import LogoIcon from '../../../components/Logos/logo-icon';
import Icon from '../../../components/Images/icon';
import H3 from '../../../components/Titles/h3';
import DescriptionSplit from '../../../components/Description/description-split';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Load images once
const imageHestiaPaths = [
    require('../../../assets/images/home/sectionHestia/hestia1.jpg'),
    require('../../../assets/images/home/sectionHestia/hestia2.jpg'),
    require('../../../assets/images/home/sectionHestia/hestia3.jpg'),
];

const Hestia = () => {
    const { t } = useTranslation();

    // Fetch only text from i18n
    const sectionData = t('home.sectionHestia', { returnObjects: true });
    const { title, subdescription, description, discover, icons } = sectionData;

    const [loadedIcons, setLoadedIcons] = useState({});
    const leftColumnRef = useRef(null);
    const [leftColumnHeight, setLeftColumnHeight] = useState(null);
    const sliderRef = useRef(null);

    useEffect(() => {
        if (Object.keys(loadedIcons).length === icons.length) return; // Prevent re-fetching if all icons are loaded

        const loadIcons = async () => {
            const iconsMap = { ...loadedIcons }; // Preserve existing icons

            await Promise.all(
                icons.map(async (item) => {
                    if (!iconsMap[item.icon]) { // Only load missing icons
                        try {
                            const icon = await import(`../../../assets/icons/home/${item.icon}.svg`);
                            iconsMap[item.icon] = icon.default;
                        } catch (error) {
                            console.error(`Error loading icon "${item.icon}":`, error);
                            iconsMap[item.icon] = null;
                        }
                    }
                })
            );

            setLoadedIcons(iconsMap);
        };

        loadIcons();
    }, [icons, loadedIcons]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === leftColumnRef.current) {
                    const height = entry.contentRect.height;
                    const newHeight = window.innerWidth < 768 ? `${100}vw` : `${height}px`;
                    setLeftColumnHeight((prev) => (prev !== newHeight ? newHeight : prev));
                }
            }
        });

        if (leftColumnRef.current) {
            resizeObserver.observe(leftColumnRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        lazyLoad: 'ondemand',
        adaptiveHeight: true
    };

    return (
        <div className="py-12 sm:px-8 md:px-24 flex flex-col-reverse md:flex-col lg:flex-row items-center">
            <div className="w-full lg:w-3/5">
                {leftColumnHeight && (
                    <div className="relative w-full" style={{ height: leftColumnHeight }}>
                        <Slider ref={sliderRef} {...settings}>
                            {imageHestiaPaths.map((image, idx) => (
                                <ImageBackground
                                    key={idx}
                                    imageUrl={image}
                                    alt={title}
                                    customHeight={leftColumnHeight}
                                    divClassName="relative h-full w-full image-border-effect"
                                    className="shadow-lg"
                                />
                            ))}
                        </Slider>

                        <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 flex justify-between">
                            <button onClick={() => sliderRef.current?.slickPrev()} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>

                            <button onClick={() => sliderRef.current?.slickNext()} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div ref={leftColumnRef} className="bg-white lg:w-2/5 flex flex-col items-start text-left border border-main shadow-2xl p-8">
                <div className='border-cta-lines border-cta-lines-2 text-main m-auto'>
                    <div className='mx-6'>
                        <LogoIcon width={70} height={70} />
                    </div>
                </div>

                <div className='w-full text-center my-6'>
                    <H2 title={title} fontWeight="bold" theme="gray-900" className="leading-tight" />
                    <H3 title={subdescription} className="tk-grafolita-script tracking-[4px] mt-2 opacity-70" fontWeight='normal' />
                </div>

                <DescriptionSplit description={description} />

                <div className='w-full text-center mt-12'>
                    <Link link="/hestia" textColor='main' disableHoverEffect={true}>
                        <span className='border-cta-lines text-main py-2 px-6'>{discover}</span>
                    </Link>
                </div>

                {/* Icons Section */}
                <div className="md:flex flex-wrap gap-4 mt-8 w-full justify-between grid grid-cols-2">
                    {icons.map((item, index) => (
                        <div key={index} className="flex flex-col items-center gap-2">
                            {loadedIcons[item.icon] ? (
                                <Icon icon={loadedIcons[item.icon]} alt={item.text} />
                            ) : (
                                <div className="w-[32px] h-[32px] bg-gray-200"></div>
                            )}
                            <span className="text-gray-700 text-sm">{item.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default memo(Hestia);