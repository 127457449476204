import React, { useState, useEffect } from 'react';
import H2 from '../../../components/Titles/h2';

function SectionPartners({ title, data }) {
    const [displayedData, setDisplayedData] = useState(data);

    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                data.map(async (item) => {
                    try {
                        const image = await import(`./../../../assets/images/${item.imgPath}`);
                        return { ...item, src: image.default };
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return null;
                    }
                })
            );

            setDisplayedData(loadedImages.filter(image => image !== null));
        };

        loadImages();
    }, [data]);

    return (
        <div className="py-12 md:py-24 w-full overflow-hidden">
            <div className="text-center mb-6">
                <H2 title={title} />
            </div>

            <div className="flex overflow-hidden w-full items-center">
                <div className="flex gap-8 md:gap-20 justify-center w-full h-fit items-center">
                    {displayedData.map((d, i) => (
                        <div key={i} className="flex justify-center flex-shrink-0 w-1/4 md:w-1/5 h-fit">
                            <img src={d.src} alt={d.alt} className="w-auto h-full" />
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
}

export default SectionPartners;