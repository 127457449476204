import React from 'react';
import H3 from '../../../components/Titles/h3';
import DescriptionSplit from '../../../components/Description/description-split';
import H6 from '../../../components/Titles/h6';
import UnorderedList from '../../../components/List/unordered-list';

export default function EventCard({ title, description, titleList, dataList }) {
    return (
        <div className={"bg-white border border-gray-200 shadow-md overflow-hidden flex flex-col h-full"}>
            <div className="image-border-effect p-6 flex flex-col h-full justify-between">
                <div className="p-6 z-20 flex flex-col justify-between h-full gap-6">
                    <div>
                        <H3 title={title} className="border-b border-main border-b-2 w-3/4 text-center m-auto pb-1" />

                        <DescriptionSplit description={description} font='text-sm' opacity="100" className="pt-4" />
                    </div>

                    <div>
                        {titleList && (
                            <H6 title={titleList} underline={true} className='pb-2' />
                        )}

                        {dataList && (
                            <UnorderedList items={dataList} font='text-sm' />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}