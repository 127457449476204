import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from './select-multiple-styles';

export default function SelectMultiple({ label, name, options, value, error, onChange, displayLabel = true, required = true, iconDisplayed = "", className = "" }) {
    const { t } = useTranslation();

    const isRequired = typeof required === 'string' ? required.toLowerCase() !== 'false' : Boolean(required);

    const formattedOptions = options.map(option => ({
        value: option.value,
        label: option.label
    }));

    const handleChange = (selectedOptions) => {
        onChange({ target: { name, value: selectedOptions ? selectedOptions.map(opt => opt.value) : [] } });
    };

    const animatedComponents = makeAnimated();

    return (
        <div className="w-full">
            {displayLabel && (
                <label htmlFor={name} className="block mb-2 text-sm text-secondary">
                    {t(`formInputs.${label}`)}
                    {!isRequired && (
                        <span className="ml-2 text-xs text-main">
                            {t("formInputs.optional")}
                        </span>
                    )}
                </label>
            )}

            <div className="relative">
                {iconDisplayed && (
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon icon={iconDisplayed} className="text-xl text-main" />
                    </div>
                )}

                <Select
                    id={name}
                    name={name}
                    placeholder={t(`formInputs.${label}`)}
                    value={formattedOptions.filter(option => value.includes(option.value))}
                    onChange={handleChange}
                    options={formattedOptions}
                    components={animatedComponents}
                    isMulti
                    styles={customStyles}
                    className={`w-full ${className}`}
                />
            </div>

            {error && <p className="mt-1 text-xs text-error ml-1">{error}</p>}
        </div>
    );
}