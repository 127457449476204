import React from "react";
import star from "../../assets/icons/global/star.svg";

export default function Stars({ count = 5 }) {
    return (
        <div className="flex gap-2">
            {Array.from({ length: count }).map((_, index) => (
                <img
                    key={index}
                    src={star}
                    alt="star"
                    className="w-6 h-6"
                />
            ))}
        </div>
    );
}
