export default function ButtonSecondary({ text, action, onClick }) {
    const handleClick = () => {
        if (onClick) {
            onClick(action);
        }
    };

    return (
        <button onClick={handleClick} className="bg-white border-2 border-solid border-main text-main py-2 px-4 transition-all duration-300 ease-in-out hover:bg-main hover:text-white">
            {text}
        </button>
    );
}
