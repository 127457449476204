import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../Inputs/input';
import DateFieldInput from '../Inputs/date-field';
import Api from '../../services/api.service';
import SelectMultiple from '../Inputs/select-multiple';
import Select from '../Inputs/select';

export default function EventInfoForm({ eventData, errors, updateEventInfoFormData, fullWidth = false }) {
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const blockedDates = Api.getBlockedDatesStored();
                setBlockedDates(blockedDates);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchData();
    }, []);

    const [blockedDates, setBlockedDates] = useState([]);

    const tenYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateEventInfoFormData({ ...eventData, [name]: value });
    };

    const handleDateChange = (dates) => {
        updateEventInfoFormData({ ...eventData, stayDates: dates });
    };

    return (
        <div className={`flex md:grid flex-col gap-6 md:gap-8 ${fullWidth ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'}`}>
            <Input label="companyName" name="companyName" value={eventData.companyName} error={errors.companyName} onChange={handleInputChange} required={false} />
            <Select label="event" name="event" value={eventData.event} options={t('events.sectionContact.typeOfEvent', { returnObjects: true }).map(option => ({
                value: option,
                label: option
            }))} error={errors.event} onChange={handleInputChange} />
            <Input label="nbPersons" name="nbPersons" value={eventData.nbPersons} error={errors.nbPersons} onChange={handleInputChange} type="number" />
            <DateFieldInput
                label='stayDates'
                value={eventData.stayDates}
                minDate={new Date()}
                maxDate={tenYearsLater}
                onChange={handleDateChange}
                displayLabel={true}
                singleDate={false}
                blockedDates={blockedDates}
            />
            <div className='col-span-2'>
                <SelectMultiple
                    label="desiredServices"
                    name="desiredServices"
                    value={eventData.desiredServices || []}  // Ensure it's an array
                    error={errors.desiredServices}
                    onChange={handleInputChange}
                    required={false}
                    className="w-full col-span-2"
                    options={t('events.sectionContact.desiredServices', { returnObjects: true }).map(option => ({
                        value: option,
                        label: option
                    }))}
                />
            </div>
        </div>
    );
}