import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import H2 from '../../../components/Titles/h2';
import Button from '../../../components/Buttons/button';
import ImageBackground from '../../../components/Images/image-background';
import Logo from '../../../components/Logos/logo';
import Stars from '../../../components/Style/stars';
import DescriptionSplit from '../../../components/Description/description-split';
import H3 from '../../../components/Titles/h3';
import Link from '../../../components/Links/link';

// Preload static image once
import imageOutside from '../../../assets/images/home/sectionDomainOverview/outside.jpg';

export default function DomainOverview() {
    const { t } = useTranslation();
    const leftColumnRef = useRef(null);
    const [leftColumnHeight, setLeftColumnHeight] = useState(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === leftColumnRef.current) {
                    const height = entry.contentRect.height;
                    const newHeight = window.innerWidth < 768 ? `${450}px` : `${height}px`;
                    setLeftColumnHeight(newHeight);
                }
            }
        });

        if (leftColumnRef.current) {
            resizeObserver.observe(leftColumnRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-4 md:gap-2 xl:gap-12 py-8 sm:py-12 md:py-16">
            {/* Left Column - Text */}
            <div ref={leftColumnRef} className='flex flex-col md:w-1/2 px-6 sm:px-8 md:px-12 lg:px-16 xl:px-28'>
                <div className='flex flex-col text-center items-center'>
                    <Logo color='dark' width={150} />

                    <div className='my-6'>
                        <Stars />
                    </div>
                </div>

                <div className="space-y-6">
                    <H2
                        title={t('home.sectionDomainOverview.title')}
                        fontWeight="bold"
                        theme="gray-900"
                        className="text-center leading-tight"
                    />
                    <H3 title={t('home.sectionDomainOverview.subdescription')} className="tk-grafolita-script opacity-70 text-center tracking-[4px]" fontWeight='normal' />

                    <DescriptionSplit description={t('home.sectionDomainOverview.description')} />

                    <div className="flex justify-center md:justify-start">
                        <Link link="/domain" disableHoverEffect={true}>
                            <Button
                                text={t('home.sectionDomainOverview.button')}
                                action="learnMore"
                                className="text-white px-6 py-3"
                            />
                        </Link>
                    </div>
                </div>
            </div>

            {/* Right Column - Image */}
            <div className="w-full md:w-1/2">
                {leftColumnHeight &&
                    <ImageBackground
                        imageUrl={imageOutside} // Use cached image
                        customHeight={leftColumnHeight}
                        alt={t('home.sectionDomainOverview.title')}
                        divClassName="relative h-full w-full image-border-effect"
                        className="shadow-lg"
                    />
                }
            </div>
        </div>
    );
}