import './App.scss';
import Home from './containers/Home/home';

function App() {
  return (
    <div className="App">
      <Home />
      <div className='hidden rounded-tr-xl rounded-tl-xl rounded-br-xl rounded-bl-xl max-w-[100px] max-h-[70px] animate-fade-in-left animate-fade-in-right animate-fade-in-up md:rounded-tr-xl md:rounded-tl-xl relative bg-white p-3 pb-1 md:p-4 md:pb-2 pl-0 pr-0 shadow-lg rounded-r-xl rounded-l-xl ' />
    </div>
  );
}

export default App;