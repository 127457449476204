import React, { useEffect, useRef, useState } from 'react';
import Link from '../../../components/Links/link';
import H3 from '../../../components/Titles/h3';
import DescriptionSplit from '../../../components/Description/description-split';
import ImageBackground from '../../../components/Images/image-background';

export default function EventCard({ imageUrl, title, description, buttonText, link, isReversed }) {
    const contentRef = useRef(null); // Ref for content div
    const [contentHeight, setContentHeight] = useState('auto'); // State for height

    useEffect(() => {
        if (contentRef.current) {
            const updateHeight = () => {
                const height = contentRef.current.offsetHeight;
                const newHeight = window.innerWidth < 768 ? `${300}px` : `${height}px`;
                setContentHeight(newHeight);
            };

            // Initial measurement
            updateHeight();

            // Observer to update on resize
            const resizeObserver = new ResizeObserver(updateHeight);
            resizeObserver.observe(contentRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    return (
        <div className={`bg-white border border-gray-200 shadow-md overflow-hidden flex flex-col h-full ${isReversed ? 'md:flex-col-reverse' : ''}`}>
            {/* Image - Takes equal space */}
            <div className="w-full h-fit md:h-1/2">
                {imageUrl ? (
                    <ImageBackground
                        imageUrl={imageUrl}
                        alt={title}
                        customHeight={contentHeight}
                    />
                ) : (
                    <div className="w-full h-full bg-gray-300 animate-pulse"></div> // Placeholder while loading or on error
                )}
            </div>

            {/* Content - Takes equal space */}
            <div ref={contentRef} className="image-border-effect p-6 flex flex-col flex-1 h-1/2 justify-between">
                {/* Title */}
                <div className="p-6 z-20 flex flex-col justify-between h-full">
                    <div>
                        <H3 title={title} className="border-b border-main border-b-2 w-5/6 md:w-3/4 text-center m-auto pb-1" />

                        {/* Description */}
                        <DescriptionSplit description={description} opacity="100" className="pt-4" />
                    </div>

                    {/* Button */}
                    <div className="w-full text-center mt-4">
                        <Link link={link} textColor="main" disableHoverEffect={true}>
                            <span className="border-cta-lines text-main py-2 px-6">{buttonText}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}