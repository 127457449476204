import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function CarouselImages({ listOfImages = [], imagesPaths = [], verticalAlign = "left", horizontalAlign = "bottom" }) {
    const sliderRef = useRef(null);
    const [images, setImages] = useState(listOfImages);

    useEffect(() => {
        const loadImages = async () => {
            if (imagesPaths.length) {
                const loadedImages = await Promise.all(
                    imagesPaths.map(async (path) => {
                        try {
                            const image = await import(`./../../assets/${path.src}`);
                            return { src: image.default, alt: path.alt };
                        } catch (error) {
                            console.error('Error loading image:', error);
                            return null;
                        }
                    })
                );
                setImages(loadedImages.filter((img) => img !== null));
            }
        };

        loadImages();
    }, [imagesPaths, listOfImages.length]);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 768, // Adjust the value based on your needs
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="relative">
            <Slider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="overflow-hidden image-border-effect">
                        <img src={image.src} alt={image.alt} className="w-full h-auto" />
                    </div>
                ))}
            </Slider>
            {images.length > 1 &&
                <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 flex justify-between">
                    <button onClick={goToPrevSlide} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    <button onClick={goToNextSlide} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            }
        </div>
    );
}