import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Images/icon';
import ImageBackground from '../../../components/Images/image-background';
import H3 from '../../../components/Titles/h3';
import DescriptionSplit from '../../../components/Description/description-split';

export default function ImageUniquePlace({ imageUrl, title, description, icon }) {
    const [imageCache, setImageCache] = useState({});
    const [iconCache, setIconCache] = useState({});

    useEffect(() => {
        if (imageCache[imageUrl] && iconCache[icon]) return; // Prevent re-fetching if both are already cached

        const loadImage = async () => {
            if (!imageCache[imageUrl]) {
                try {
                    const image = await import(`../../../assets/images/home/sectionUniquePlace/${imageUrl}`);
                    setImageCache((prev) => ({ ...prev, [imageUrl]: image.default }));
                } catch (error) {
                    console.error('Error loading image:', error);
                }
            }
        };

        const loadIcon = async () => {
            if (!iconCache[icon]) {
                try {
                    const iconImage = await import(`../../../assets/icons/home/${icon}.svg`);
                    setIconCache((prev) => ({ ...prev, [icon]: iconImage.default }));
                } catch (error) {
                    console.error('Error loading icon:', error);
                }
            }
        };

        loadImage();
        loadIcon();
    }, [imageUrl, icon, imageCache, iconCache]); // Now satisfies ESLint but prevents unnecessary re-fetching

    return (
        <div className="relative w-full h-[400px] sm:h-[450px] md:h-[500px] lg:h-[600px] xl:h-[800px] image-border-effect">
            {/* Background Image */}
            <div className="absolute inset-0">
                {imageCache[imageUrl] ? (
                    <ImageBackground imageUrl={imageCache[imageUrl]} alt={title} />
                ) : (
                    <div className="w-full h-full bg-gray-200 animate-pulse image-border-effect"></div>
                )}
            </div>

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-40"></div>

            {/* Content */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-center text-white">
                {/* Icon */}
                {iconCache[icon] && (
                    <div className="mb-4">
                        <Icon
                            icon={iconCache[icon]}
                            alt={`${title} Icon`}
                            maxWidth="65px"
                            maxHeight="65px"
                        />
                    </div>
                )}

                <H3 title={title} />

                <DescriptionSplit description={description} theme='white' opacity='100' className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-20 2xl:px-24"
                    text='center' />
            </div>
        </div>
    );
}