import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import Logo from '../../../components/Logos/logo';
import Stars from '../../../components/Style/stars';
import defaultBackgroundImage from '../../../assets/images/domain/house/house.png';
import ImageBackground from '../../../components/Images/image-background';

function HeaderVideo() {
    const { t, i18n } = useTranslation();
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [animationSequence, setAnimationSequence] = useState([]);
    const [animationKey, setAnimationKey] = useState(0); // Force re-render key

    /**
     * Update animation sequence and force re-render whenever the language changes
     */
    useEffect(() => {
        const textAnimationArray = t('home.sectionVideo.textAnimation', { returnObjects: true });

        // Update the sequence and change key to force re-render
        setAnimationSequence(textAnimationArray.flatMap((text) => [text, 2000]));
        setAnimationKey((prevKey) => prevKey + 1); // Increment key to refresh TypeAnimation
    }, [t, i18n.language]); // Track language changes

    return (
        <div className='w-full h-screen'>
            <div className='player-wrapper'>
                <ImageBackground
                    imageUrl={defaultBackgroundImage}
                    divClassName={`
                        absolute inset-0 z-40 w-full h-full object-cover 
                        ${isVideoReady ? 'opacity-0 hidden' : 'opacity-100'}
                    `}
                />

                <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/1053757553"
                    playing
                    loop
                    muted
                    controls={false}
                    onStart={() => setIsVideoReady(true)}
                />
            </div>

            {/* Overlay */}
            <div className="z-50 absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-center px-4 z-10">
                <Logo color="white" height={200} />
                <div className="mt-6 mb-12">
                    <Stars />
                </div>

                <h1 className="text-white text-2xl sm:text-3xl md:text-5xl font-bold drop-shadow-md leading-snug">
                    {t('home.sectionVideo.textStatic')}
                </h1>

                {/* Animated Text */}
                <div className="text-white text-2xl sm:text-4xl md:text-6xl font-medium mt-4 drop-shadow-md tk-grafolita-script tracking-[4px]">
                    {animationSequence.length > 0 && ( // Ensure sequence is loaded before rendering
                        <TypeAnimation
                            key={animationKey} // Change key to force re-render
                            sequence={animationSequence}
                            wrapper="h2"
                            speed={50}
                            className="tk-grafolita-script tracking-[4px]"
                            repeat={Infinity} // Infinite loop
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default HeaderVideo;