import Helper from "../../services/helper.service";

export default function Button({ text, action, onClick, className = "", disabled = false, error = "" }) {
    const handleClick = () => {
        if (disabled) {
            return Helper.toast(error, "error");
        }

        if (onClick && !disabled) {
            onClick(action);
        }
    };

    return (
        <button onClick={handleClick} className={`bg-main text-white py-2 md:py-3 px-3 md:px-4 transition-all duration-300 ease-in-out hover:bg-main-dark ${className}`}>
            {text}
        </button>
    );
}
