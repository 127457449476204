import React, { useState, useEffect } from 'react';

export default function H3({ title, fontWeight = 'bold', theme = "title", className = "" }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Function to strip HTML tags (like <br>) for mobile
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };

    return (
        <h3
            className={`text-lg md:text-xl font-${fontWeight} text-${theme} ${className}`}
            {...(!isMobile ? { dangerouslySetInnerHTML: { __html: title } } : {})}
        >
            {isMobile ? stripHtmlTags(title) : null}
        </h3>
    );
}