import React, { memo, useMemo } from 'react';

const ImageBackground = memo(({
    imageUrl = "",
    alt = "",
    customHeight,
    customWidth,
    divClassName = "relative h-full w-full",
    children,
}) => {
    const containerStyle = useMemo(() => {
        const style = {
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        };

        if (customHeight) {
            style.height = customHeight;
            style.maxHeight = customHeight;
        }

        if (customWidth) {
            style.width = customWidth;
            style.maxWidth = customWidth;
        }

        return style;
    }, [imageUrl, customHeight, customWidth]);

    return (
        <div
            style={containerStyle}
            className={divClassName}
            role="img"
            aria-label={alt}
        >
            {children}
        </div>
    );
});

export default ImageBackground;