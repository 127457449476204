import Input from '../../../../components/Inputs/input';

export default function BillingInfoFormQuotation({ data, customerType = "physique", errors, updateBillingInfoFormData }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateBillingInfoFormData({ ...data, [name]: value });
    };

    return (
        <div className="grid gap-6 md:gap-8 grid-cols-1 md:grid-cols-2">
            {customerType === "morale" &&
                <>
                    <Input label="companyName" name="companyName" value={data.companyName} error={errors.companyName} onChange={handleInputChange} required={customerType === "morale" ? true : false} />
                    <Input label="siret" name="siret" value={data.siret} error={errors.siret} onChange={handleInputChange} required={customerType === "morale" ? true : false} />
                    <Input label="VATNumber" name="VATNumber" value={data.VATNumber} onChange={handleInputChange} required={false} />
                </>
            }
            <Input label="country" name="country" value={data.country} error={errors.country} onChange={handleInputChange} />
            <Input label="postalCode" name="postalCode" value={data.postalCode} error={errors.postalCode} onChange={handleInputChange} />
            <Input label="city" name="city" value={data.city} error={errors.city} onChange={handleInputChange} />
            <Input label="address" name="address" value={data.address} error={errors.address} onChange={handleInputChange} />
            <Input label="address2" name="address2" value={data.address2} onChange={handleInputChange} required={false} />
        </div>
    )
};