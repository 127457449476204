import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BackgroundImage from "../../components/Banner/background-header";
import imageBanner from '../../assets/images/home/banner-home.png';

export default function ErrorPage() {
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-center h-screen bg-backgroundColor">
            <Helmet>
                <title>{t('errorPage.title')}</title>
            </Helmet>

            <BackgroundImage image={imageBanner} alt={t('home.sectionBanner.altBackground')} displayGradient={false} />

            <div className="absolute m-4 text-center p-8 bg-white shadow-md max-w-lg">
                <h1 className="text-6xl font-bold text-main">404</h1>
                <p className="text-xl mt-4 text-gray-700">{t('errorPage.message')}</p>
                <p className="mt-4 text-gray-600">
                    {t('errorPage.description')}
                    <Link to="/" className="text-gray-800 hover:underline ml-1">{t('errorPage.homeLink')}</Link>.
                </p>
                <Link
                    to="/"
                    className="mt-6 inline-block bg-main text-white py-2 px-4"
                >
                    {t('errorPage.backHome')}
                </Link>
            </div>
        </div>
    );
}
