import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import imageHeaderBanner from "./../../../assets/images/domain/house/house.png";

import H1 from "../../../components/Titles/h1";
import Button from "../../../components/Buttons/button";
import DescriptionSplit from "../../../components/Description/description-split";
import BackgroundImage from "../../../components/Banner/background-header";
import RoomDetails from "../components/room-details";
import { Helmet } from "react-helmet";

import imageData from './../../../utils/imports/import-images.json'; // Adjust the path to where your JSON file is located

const listOfRooms = ['livingRoom', 'kitchen', 'diningRoom', 'smallLivingRoom'];
const listOfSuits = ['nacreSuit', 'topazeSuit', 'onyxSuit', 'amazoniteSuit', 'seleniteSuit'];

export default function HouseDetails() {
    const { t } = useTranslation();

    const scrollTargetRooms = useRef(null);
    const scrollTargetSuits = useRef(null);

    const [headerHeight, setHeaderHeight] = useState(0);

    const extractImages = (list, category) => {
        return list.reduce((acc, item) => {
            if (imageData['house'][category][item]) {
                acc[item] = imageData['house'][category][item];
            }
            return acc;
        }, {});
    }

    // Extracting images for rooms and suits
    const roomImages = extractImages(listOfRooms, 'rooms');
    const suitImages = extractImages(listOfSuits, 'suits');

    useEffect(() => {
        const header = document.querySelector('header');
        if (header) {
            const height = header.offsetHeight;
            setHeaderHeight(height);
        }
    }, []);

    const handleScrollToDiv = (ref) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - headerHeight - 10,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div>
            <Helmet>
                <title>{t('houseDetails.page')}</title>
                <meta name="description" content={t('houseDetails.description')} />
                <meta name="keywords" content={t('houseDetails.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-12 md:bottom-24 w-4/5 md:w-3/5 text-center">
                <H1 title={t('houseDetails.title')} />

                <div className="mt-4 mb-6">
                    <DescriptionSplit description={t('houseDetails.titleDescription')} text='center' />
                </div>

                <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-20">
                    <Button text={t('houseDetails.buttonLivingRooms')} onClick={() => handleScrollToDiv(scrollTargetRooms)} />
                    <Button text={t('houseDetails.buttonSuits')} onClick={() => handleScrollToDiv(scrollTargetSuits)} />
                </div>
            </div>

            <div className="max-w-[2000px] m-auto">
                <div ref={scrollTargetRooms} className="relative mt-[100vh]">
                    {listOfRooms.map((room, index) => (
                        <RoomDetails key={t(`houseDetails.${room}.title`)} data={t(`houseDetails.${room}`, { returnObjects: true })}
                            imagesPaths={roomImages[room]} carouselOn={index % 2 === 0 ? 'left' : 'right'} />
                    ))}
                </div>

                <div ref={scrollTargetSuits} className="">
                    <div className="my-8 md:my-12 bg-secondary py-4 md:py-6 px-12 md:px-24 w-fit m-auto">
                        <H1 title={t("houseDetails.suits")} fontWeight="semibold" theme="white" />
                    </div>

                    {listOfSuits.map((suit, index) => (
                        <RoomDetails key={t(`houseDetails.${suit}.title`)} data={t(`houseDetails.${suit}`, { returnObjects: true })}
                            imagesPaths={suitImages[suit]} carouselOn={index % 2 === 0 ? 'left' : 'right'} />
                    ))}
                </div>
            </div>
        </div>
    );
}