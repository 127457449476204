import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Icons() {
    const { t } = useTranslation();

    // Fetch only text from i18n
    const iconsData = t('home.sectionIcons', { returnObjects: true });

    const [loadedIcons, setLoadedIcons] = useState({});

    useEffect(() => {
        if (Object.keys(loadedIcons).length === iconsData.length) return; // Prevent re-fetching if all icons are loaded

        const loadIcons = async () => {
            const iconsMap = { ...loadedIcons }; // Preserve existing icons

            await Promise.all(
                iconsData.map(async (item) => {
                    if (!iconsMap[item.icon]) { // Only load missing icons
                        try {
                            const icon = await import(`../../../assets/icons/home/${item.icon}.svg`);
                            iconsMap[item.icon] = icon.default;
                        } catch (error) {
                            console.error(`Error loading icon "${item.icon}":`, error);
                            iconsMap[item.icon] = null; // Fallback for missing icons
                        }
                    }
                })
            );

            setLoadedIcons(iconsMap);
        };

        loadIcons();
    }, [iconsData, loadedIcons]); // Now satisfies ESLint, but prevents unnecessary re-fetching

    return (
        <div className="py-24 px-4 sm:px-8 md:px-24">
            <div className="border border-main px-6 md:px-12 py-12 md:py-18 bg-white">
                <div className="md:flex md:justify-between md:items-start grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-6 md:gap-12 items-center justify-items-center">
                    {iconsData.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center text-center"
                        >
                            {/* Icon */}
                            {loadedIcons[item.icon] ? (
                                <img
                                    src={loadedIcons[item.icon]}
                                    alt={item.text}
                                    className="w-12 h-12 mb-2"
                                />
                            ) : (
                                <div className="w-12 h-12 mb-2 bg-gray-200 rounded-full animate-pulse"></div> // Fallback for missing icons
                            )}
                            {/* Text updates dynamically */}
                            <span className="text-gray-900 text-sm font-semibold">
                                {item.text}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}