import React from "react";
import { useTranslation } from "react-i18next";

import imageHeaderBanner from "./../../../assets/images/domain/outside/outside.jpg";

import H1 from "../../../components/Titles/h1";
import DescriptionSplit from "../../../components/Description/description-split";
import BackgroundImage from "../../../components/Banner/background-header";
import RoomDetails from "../components/room-details";
import { Helmet } from "react-helmet";

import imageData from './../../../utils/imports/import-images.json'; // Adjust the path to where your JSON file is located

const rooms = ['pools', 'gardens', 'sportsHall'];

export default function GardenDetails() {
    const { t } = useTranslation();

    const extractImages = (list) => {
        return list.reduce((acc, item) => {
            if (imageData['garden'][item]) {
                acc[item] = imageData['garden'][item];
            }
            return acc;
        }, {});
    }

    const roomImages = extractImages(rooms);

    return (
        <div>
            <Helmet>
                <title>{t('gardenDetails.page')}</title>
                <meta name="description" content={t('gardenDetails.description')} />
                <meta name="keywords" content={t('gardenDetails.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-12 md:bottom-24 w-4/5 md:w-3/5 text-center">
                <H1 title={t('gardenDetails.title')} />

                <div className="mt-4 mb-6">
                    <DescriptionSplit description={t('gardenDetails.titleDescription')} text='center' />
                </div>
            </div>

            <div className="max-w-[2000px] m-auto relative mt-[100vh]">
                {rooms.map((room, index) => (
                    <RoomDetails key={t(`gardenDetails.${room}.title`)} data={t(`gardenDetails.${room}`, { returnObjects: true })}
                        imagesPaths={roomImages[room]} carouselOn={index % 2 === 0 ? 'left' : 'right'} />
                ))}
            </div>
        </div>
    );
}