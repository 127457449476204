import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import imageHeaderBanner from "./../../assets/images/global/imageHeaderBanner.png";
// import Button from "../../components/Buttons/button";
import BackgroundImage from "../../components/Banner/background-header";
import H1 from "../../components/Titles/h1";
import H2 from "../../components/Titles/h2";

import FAQ from "./components/faq";
import { Helmet } from "react-helmet";
import useIntersectionObserver from "../../utils/hooks/intersection-observer";

export default function Infos() {
    const { t } = useTranslation();

    const titleArrivalAndDepartureRef = useRef(null);
    const titlePreparingStayRef = useRef(null);
    const titleCateringRef = useRef(null);
    const titleWellBeingRef = useRef(null);
    const titleTransportRef = useRef(null);
    const titleReservationRef = useRef(null);

    useIntersectionObserver(
        [titleArrivalAndDepartureRef, titlePreparingStayRef, titleCateringRef, titleWellBeingRef, titleTransportRef, titleReservationRef],
        ["animate-fade-in-left", "animate-fade-in-left", "animate-fade-in-left", "animate-fade-in-left", "animate-fade-in-left", "animate-fade-in-left"]);

    return (
        <div>
            <Helmet>
                <title>{t('infos.page')}</title>
                <meta name="description" content={t('infos.description')} />
                <meta name="keywords" content={t('infos.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 w-4/5 md:w-3/5 text-center">
                <H1 title={t('infos.title')} />
            </div>

            <div className="max-w-[2000px] relative mt-[100vh] w-10/12 m-auto mb-12">
                <div>
                    <div ref={titleArrivalAndDepartureRef} className="opacity-0">
                        <H2 title={t('infos.arrivalAndDeparture.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.arrivalAndDeparture.data', { returnObjects: true })} />
                </div>

                <div className="mt-12 md:mt-20">
                    <div ref={titlePreparingStayRef} className="opacity-0">
                        <H2 title={t('infos.preparingStay.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.preparingStay.data', { returnObjects: true })} />
                </div>

                <div className="mt-12 md:mt-20">
                    <div ref={titleCateringRef} className="opacity-0">
                        <H2 title={t('infos.catering.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.catering.data', { returnObjects: true })} />
                </div>

                <div className="mt-12 md:mt-20">
                    <div ref={titleWellBeingRef} className="opacity-0">
                        <H2 title={t('infos.wellBeing.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.wellBeing.data', { returnObjects: true })} />
                </div>

                <div className="mt-12 md:mt-20">
                    <div ref={titleTransportRef} className="opacity-0">
                        <H2 title={t('infos.transport.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.transport.data', { returnObjects: true })} />
                </div>

                <div className="mt-12 md:mt-20">
                    <div ref={titleReservationRef} className="opacity-0">
                        <H2 title={t('infos.reservation.title')} className="w-fit border-b-2 border-solid border-main pb-2" />
                    </div>

                    <FAQ data={t('infos.reservation.data', { returnObjects: true })} />
                </div>
            </div>
        </div>
    );
}