import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonnalInfoFormQuotation from '../../../../components/Forms/personnal-info-form-quotation';
import BillingInfoFormQuotation from './billing-info-form';
import Hr from '../../../../components/Style/hr';
import Checkbox from '../../../../components/Inputs/checkbox';
import ButtonSecondary from '../../../../components/Buttons/button-secondary';
import Button from '../../../../components/Buttons/button';
import ExplanationStep from '../explanation-step';
import Validator from '../../../../services/validator.service';
import Api from '../../../../services/api.service';
import Helper from '../../../../services/helper.service';

export default function AcceptQuotationForm({ quotation, returnedData }) {
    const [action, setAction] = useState();
    const [checkbox1Checked, setCheckbox1Checked] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const { t } = useTranslation();

    /* Radio button state */
    const [customerType, setCustomerType] = useState('physique');

    /* Personnal infos */
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState({
        lastname: quotation.customer.lastname,
        firstname: quotation.customer.firstname,
        email: quotation.customer.email,
        birthdate: quotation.customer.birthdate,
        phone: quotation.customer.phone
    });

    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    /* Billing info */
    const [billingInfoFormData, setBillingInfoFormData] = useState({
        companyName: quotation.customer.companyName,
        siret: quotation.customer.siret,
        VATNumber: quotation.customer.VATNumber,
        country: quotation.address.country,
        city: quotation.address.city,
        address: quotation.address.address,
        postalCode: quotation.address.postalCode,
        address2: quotation.address.address2
    });

    const updateBillingInfoFormData = (newData) => { setBillingInfoFormData(newData) };
    const [errorsBillingInfo, setErrorsBillingInfo] = useState({});

    async function handleButtonActionClick(action) {
        if (action === "refused") {
            setAction(action);
            const response = await Api.changeStatusQuotation(quotation._id, action);
            Helper.handleApiResponse(response, t('responses.quotationRefused'), t('responses.quotationError'));
            returnedData(action);

        } else if (action === "modify") {
            setAction(action);

        } else if (action === "cancelModify") {
            setAction(null);

        } else if (action === "accept") {
            // Validate forms
            const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
            const tmpErrorsBillingInfo = Validator.validateBillingInfoFormData(billingInfoFormData, t, customerType)

            setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);
            setErrorsBillingInfo(tmpErrorsBillingInfo);

            // Check if there are any errors
            const isPersonalInfoValid = Object.keys(tmpErrorsPersonnalInfo).length === 0;
            const isBillingInfoValid = Object.keys(tmpErrorsBillingInfo).length === 0;

            if (!checkbox1Checked) {
                setCheckboxError(true);
                return;
            } else {
                setCheckboxError(false);
            }

            if (isPersonalInfoValid && isBillingInfoValid) {
                // The form data is valid
                quotation = Helper.setDataToQuotation(quotation, personnalInfoFormData, billingInfoFormData);
                quotation.mainInfos.status = "accepted";
                console.log(quotation);

                const response = await Api.saveQuotation(quotation);
                console.log(response);

                Helper.handleApiResponse(response, t('responses.quotationAccepted'), t('responses.quotationError'));

                // const responseEmail = await Api.sendQuotation(quotation);
                // Helper.handleApiResponse(responseEmail, t('responses.invoiceSent'), t('responses.invoiceError'));
                returnedData("step3");

            } else {
                console.log('Personal Info Errors:', tmpErrorsPersonnalInfo);
                console.log('Billing info errors :', tmpErrorsBillingInfo);
            }
        }
    };

    return (
        <div className='px-4 md:px-0'>
            <div className="flex flex-col gap-5 md:flex-row md:gap-40">
                <div className="w-full md:w-64 flex flex-col gap-5">
                    <ExplanationStep
                        title={t('pageQuotation.step1.personalAndBillingInformation')}
                        description={t('pageQuotation.step1.descriptionPersonalAndBillingInformation')} />
                </div>

                <div className="flex flex-col w-full">
                    <div className="mb-4">
                        <div className="mt-2 flex justify-around">
                            <label className="flex items-center gap-2 cursor-pointer">
                                <input type="radio" name="customerType" value="physique" checked={customerType === 'physique'} onChange={() => setCustomerType('physique')} className="text-main checked:bg-main focus:ring-transparent focus:ring-0" />
                                {t('pageQuotation.step1.personnePhysique')}
                            </label>
                            <label className="flex items-center gap-2 cursor-pointer">
                                <input type="radio" name="customerType" value="morale" checked={customerType === 'morale'} onChange={() => setCustomerType('morale')} className="text-main checked:bg-main focus:ring-transparent focus:ring-0" />
                                {t('pageQuotation.step1.personneMorale')}
                            </label>
                        </div>
                    </div>

                    <PersonnalInfoFormQuotation customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} />

                    <Hr />

                    <BillingInfoFormQuotation data={billingInfoFormData} errors={errorsBillingInfo} customerType={customerType} updateBillingInfoFormData={updateBillingInfoFormData} />
                </div>
            </div>

            <Hr />

            <Checkbox label="checkbox1" onChange={setCheckbox1Checked} />
            {checkboxError && (
                <p className="mt-1 text-sm text-error ml-1">{t('errors.checkboxError')}</p>
            )}

            {!action && (
                <div className="flex flex-col-reverse md:flex-row md:justify-between gap-5 my-10 ">
                    <ButtonSecondary text={t('inputs.refuse')} action="refused" onClick={handleButtonActionClick} />
                    <Button text={t('inputs.next')} action="accept" onClick={handleButtonActionClick} />
                </div>
            )}
        </div>
    )
};