import React, { useRef } from "react";
import H2 from "../../../components/Titles/h2";
import H3 from "../../../components/Titles/h3";
import DescriptionSplit from "../../../components/Description/description-split";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight, faStar, faStarHalf, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel-testimonials.scss";

export default function SectionTestimonials({ title, data }) {
    const testimonialsToShow = data.slice(0, 3);
    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        adaptiveHeight: true
    };

    const goToNextSlide = () => {
        sliderRef.current?.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current?.slickPrev();
    };

    return (
        <div className="w-full bg-testimonials p-12 md:p-20">
            <div className='max-w-[2000px] text-center m-auto'>
                <div className="w-full relative">
                    <H2 title={title} className="mb-6 md:mb-14" />

                    {/* Mobile Slider */}
                    <div className="block md:hidden relative">
                        <Slider ref={sliderRef} {...settings}>
                            {testimonialsToShow.map((testimonial, index) => (
                                <div key={index}>
                                    <div className="bg-white py-6 px-8 relative max-w-sm flex flex-col justify-between shadow-md mx-auto">
                                        <FontAwesomeIcon icon={faQuoteRight} className="absolute top-[-6px] right-0.5 text-main text-4xl" />
                                        <DescriptionSplit description={testimonial.description} />
                                        <div>
                                            <div className="flex justify-center gap-2 my-5">
                                                {Array.from({ length: Math.floor(testimonial.stars) }, (_, i) => (
                                                    <FontAwesomeIcon key={i} icon={faStar} className="text-main" />
                                                ))}
                                                {testimonial.stars % 1 !== 0 && <FontAwesomeIcon icon={faStarHalf} className="text-main" />}
                                            </div>
                                            <H3 title={testimonial.author} className="text-center mt-6" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                        <div className="absolute -left-10 top-1/2 transform -translate-y-1/2 z-10">
                            <button onClick={goToPrevSlide} className="bg-gray-100 text-gray-500 py-4 px-6 rounded-full">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                        </div>
                        <div className="absolute -right-10 top-1/2 transform -translate-y-1/2 z-10">
                            <button onClick={goToNextSlide} className="bg-main text-white py-4 px-6 rounded-full">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>

                    {/* Desktop Layout */}
                    <div className="hidden md:flex flex-wrap justify-center gap-8">
                        {testimonialsToShow.map((testimonial, i) => (
                            <div key={i} className={`bg-white py-6 px-8 relative flex flex-col justify-between shadow-md ${testimonialsToShow.length === 2 ? 'w-1/2' : 'max-w-sm'}`}>
                                <FontAwesomeIcon icon={faQuoteRight} className="absolute -top-4 -right-4 text-main text-4xl" />
                                <DescriptionSplit description={testimonial.description} />
                                <div>
                                    <div className="flex justify-center gap-2 my-5">
                                        {Array.from({ length: Math.floor(testimonial.stars) }, (_, index) => (
                                            <FontAwesomeIcon key={index} icon={faStar} className="text-main" />
                                        ))}
                                        {testimonial.stars % 1 !== 0 && <FontAwesomeIcon icon={faStarHalf} className="text-main" />}
                                    </div>
                                    <H3 title={testimonial.author} className="text-center mt-6" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}