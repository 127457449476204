import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./photo-gallery.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCamera } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import imageData from './../../../utils/imports/import-all-images.json'; // Import image data
import Loading from '../../../components/Loading/loading';

export default function SectionPhotoGallery() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [alreadyOpened, setAlreadyOpened] = useState(false); // Track loading state

    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                imageData.map(async (imgObj) => {
                    try {
                        const imageModule = await import(`./../../../assets/${imgObj.src}`);
                        return {
                            original: imageModule.default,
                            thumbnail: imageModule.default,
                        };
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return null;
                    }
                })
            );

            setImages(loadedImages.filter((img) => img !== null));
        };

        loadImages();
    }, []);

    const memoizedImages = useMemo(() => images, [images]);

    const toggleGallery = useCallback(() => {
        if (isOpen) {
            setModalVisible(false);
            setAlreadyOpened(true);
            setTimeout(() => setIsOpen(false), 200); // Faster closing animation
        } else {
            setIsLoading(true);
            setIsOpen(true);
            requestAnimationFrame(() => setModalVisible(true)); // Instant UI response
            setTimeout(() => {
                setIsLoading(false);
            }, alreadyOpened ? 500 : 2000);
        }
    }, [isOpen, alreadyOpened]);

    const handleBackgroundClick = useCallback((e) => {
        if (e.target.id === "gallery-background") {
            toggleGallery();
        }
    }, [toggleGallery]);

    return (
        <div className="pt-6 md:pt-12 md:pb-12 p-6 flex bg-secondary justify-center align-center">
            {/* Button to open gallery */}
            <button
                className="bg-main text-white px-4 py-3 flex items-center space-x-2 cursor-pointer"
                onClick={toggleGallery}>
                <FontAwesomeIcon icon={faCamera} />
                <span>{t('home.sectionPhotoGallery.button')}</span>
            </button>

            {/* Gallery Modal */}
            {isOpen && (
                <div
                    id="gallery-background"
                    className={`z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out ${modalVisible ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                    onClick={handleBackgroundClick}
                >
                    <div className={`bg-white p-4 pt-0 shadow-lg max-w-4xl max-h-screen overflow-y-auto transform transition-transform duration-300 ease-in-out ${modalVisible
                        ? 'scale-100 translate-y-0 opacity-100'
                        : 'scale-90 translate-y-10 opacity-0'
                        }`}>
                        {/* Close button */}
                        <div className="flex justify-end items-center">
                            <button
                                className="p-3 text-gray-500 hover:text-gray-600 transform hover:scale-110 transition duration-200"
                                onClick={toggleGallery}
                            >
                                <FontAwesomeIcon icon={faXmark} className="text-xl" />
                            </button>
                        </div>
                        {isLoading && (
                            <div className="flex justify-center items-center min-h-[300px] min-w-[300px]">
                                <Loading />
                            </div>
                        )}
                        <div className={isLoading ? 'hidden' : ''}>
                            <ImageGallery
                                items={memoizedImages}
                                lazyLoad={true}
                                loading="lazy"
                                thumbnailLoading="lazy"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}