export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: "46px", // Prevent shrink
        borderColor: state.isFocused ? "#000000" : "#d1d5db", // Main color on focus
        boxShadow: "none", // Subtle glow
        "&:hover": {
            borderColor: "#d1d5db", // Change border color on hover
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: "46px", // Ensure selected values fit properly
        padding: "5px 8px",
    }),
    input: (provided) => ({
        ...provided,
        margin: "0px", // Remove extra spacing
        padding: "4px",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        minHeight: "46px", // Align dropdown indicator
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#000000" : state.isFocused ? "#F1E2BE" : "white", // Main color for selected & hover
        color: state.isSelected ? "white" : "#333",
        "&:hover": {
            backgroundColor: "#E5C785", // Light gold on hover
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: "#d1d5db", // Background for selected values
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "black", // White text for selected values
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#B8934A", // Darker gold on hover
            color: "white",
        },
    }),
};