import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from '../components/event-card';

export default function Events() {
    const { t } = useTranslation();

    // Fetch only text from i18n
    const eventsData = t('home.sectionEvents', { returnObjects: true });

    // Store images separately to prevent reloading
    const [imageCache, setImageCache] = useState({});

    useEffect(() => {
        if (Object.keys(imageCache).length === eventsData.length) return; // Prevent re-fetching if all images are loaded

        const loadImages = async () => {
            const imagesMap = { ...imageCache }; // Preserve existing images

            await Promise.all(
                eventsData.map(async (event) => {
                    if (!imagesMap[event.image]) { // Only load missing images
                        try {
                            const image = await import(`../../../assets/images/home/sectionEvents/${event.image}`);
                            imagesMap[event.image] = image.default;
                        } catch (error) {
                            console.error(`Error loading image "${event.image}":`, error);
                            imagesMap[event.image] = null; // Fallback
                        }
                    }
                })
            );

            setImageCache(imagesMap);
        };

        loadImages();
    }, [eventsData, imageCache]); // Now satisfies ESLint, but prevents unnecessary re-fetching

    return (
        <div className="py-12 md:px-4 sm:px-8 md:px-16">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 auto-rows-fr">
                {eventsData.map((event, index) => (
                    <EventCard
                        key={index}
                        imageUrl={imageCache[event.image]} // Use cached image
                        title={event.title}
                        description={event.description}
                        buttonText={event.discover}
                        link={event.link}
                        isReversed={index === 1} // Pass true for the second item (index 1)
                    />
                ))}
            </div>
        </div>
    );
}