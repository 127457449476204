import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageUniquePlace from '../components/image-unique-place';
import H2 from '../../../components/Titles/h2';
import LogoIcon from '../../../components/Logos/logo-icon';
import Stars from '../../../components/Style/stars';
import DescriptionSplit from '../../../components/Description/description-split';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import iconLogo from '../../../assets/images/logos/Icon_Artbanne.png';
import FullWidthImage from '../../../components/Images/image-full-width';

export default function UniquePlace() {
    const { t } = useTranslation();
    const sliderRef = useRef(null);

    // Extract data from i18n
    const sectionData = t('home.sectionUniquePlace', { returnObjects: true });
    const { title, description, images } = sectionData;

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="py-12 md:py-24 sm:px-8 md:px-24">
            {/* Section Header */}
            <div className="flex flex-col items-center text-center px-4 mb-8 md:mb-24">
                <LogoIcon />
                <div className='mt-2 mb-6'>
                    <Stars />
                </div>
                {/* Title */}
                <H2
                    title={title}
                    fontWeight="bold"
                    theme="gray-900"
                    className="leading-tight"
                />
                {/* Description */}
                <DescriptionSplit description={description} text='center' className='max-w-3xl mt-2' />
            </div>

            {/* Mobile: Carousel */}
            <div className="block md:hidden relative">
                <Slider ref={sliderRef} {...settings}>
                    {images.map((item, index) => (
                        <div key={index}>
                            <ImageUniquePlace
                                key={index}
                                imageUrl={item.image}
                                title={item.title}
                                description={item.description}
                                icon={item.icon}
                            />
                        </div>
                    ))}
                </Slider>

                {images.length > 1 &&
                    <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 flex justify-between">
                        <button onClick={goToPrevSlide} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>

                        <button onClick={goToNextSlide} className="bg-main text-white text-2xl py-1 px-2 flex items-center">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                }
            </div>

            {/* Desktop: Grid */}
            <div className="relative hidden md:flex flex-wrap justify-center gap-12 h-[848px] sm:h-[948px] md:h-[1048px] lg:h-[1248px] xl:h-[1648px]">
                <FullWidthImage url={iconLogo} alt='logo' customHeight="33%" customWidth="66%" divClassName="flex justify-end absolute -top-16 right-16 opacity-10" />
                {images.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col items-center justify-center bg-gray-100 ${index % 2 !== 0 ? "translate-y-36" : "translate-y-0"}`}
                        style={{ width: "calc(50% - 32px)" }}>
                        <ImageUniquePlace
                            key={index}
                            imageUrl={item.image}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}