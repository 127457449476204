import React from 'react';
import Input from '../Inputs/input';
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from 'react-i18next';

export default function PersonnalInfoForm({ customer, errors, updatePersonnalInfoFormData, fullWidth = false }) {
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updatePersonnalInfoFormData({ ...customer, [name]: value });
    };

    const handlePhoneChange = (value) => {
        updatePersonnalInfoFormData({ ...customer, phone: value });
    };

    return (
        <div className={`grid gap-6 md:gap-8 ${fullWidth ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'}`}>
            <Input label="lastname" name="lastname" value={customer.lastname} error={errors.lastname} onChange={handleInputChange} />
            <Input label="firstname" name="firstname" value={customer.firstname} error={errors.firstname} onChange={handleInputChange} />
            <Input label="email" name="email" value={customer.email} error={errors.email} onChange={handleInputChange} />
            {/* <Input label="phone" name="phone" value={customer.phone} error={errors.phone} onChange={handleInputChange} /> */}
            <div className="w-full rounded-md">
                <label className="block mb-2 text-sm text-secondary">
                    {t("formInputs.phone")}
                </label>
                <PhoneInput
                    defaultCountry="FR"
                    placeholder={t("formInputs.phone")}
                    value={customer.phone}
                    onChange={handlePhoneChange}
                    className={`w-full text-sm text-secondary bg-white border rounded-md ${errors.phone ? "border-error" : "border-borderColor"}`}
                />
                {errors.phone && <p className="mt-1 text-xs text-error ml-1">{errors.phone}</p>}
            </div>

        </div>
    );
}
